.invoice-viewer {
  h5 {
    font-size: 15px;
  }

  .viewer__order-info {
  }

  .viewer__billing-info {
  }
}

// Media print

@media all {
  .page-break {
    display: none;
  }

  .hideInPrint {
    display: none;
  }
}

.titleCard {
  flex: 2;
}

.subCard {
  flex: 1;
}

@media print {
  body,
  // *,
  html {
    // visibility: hidden;
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  .break-in-print {
    page-break-inside: auto !important;
    break-inside: auto !important;
  }

  .printActionPlan {
    break-inside: avoid;
    page-break-inside: avoid;
  }

  .page-break {
    margin-top: 1rem;
    display: block;
    visibility: hidden;
    page-break-before: always !important;
    //break-after: always !important;
    // position: relative !important;
    //-webkit-region-break-after: always !important;
    //page-break-after: always !important;
  }

  .hideInPrint {
    display: block;
  }

  .question {
    break-inside: avoid;
    //background-color: aquamarine;
    page-break-inside: avoid;
  }

  .questionHeader {
    //background-color: greenyellow;
    margin-top: 10px;
    margin-bottom: 10px;
    // font-size: 24;
  }

  .sectionHeader {
    text-align: center;
    page-break-before: always;
    margin-top: 20px;
    margin-bottom: 20px;
    //background-color: red;
    flex-grow: 1 !important;
    width: 100% !important;
  }

  .titleContainer {
    //flex-grow: 1 !important;
    width: 100% !important;
  }

  .ps {
    overflow: scroll !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
    height: auto !important;
    position: relative !important;
  }

  @page {
    size: auto;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  #print-area {
    //position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    * {
      visibility: visible;
      position: relative !important;
      //display: block;
      width: auto;
      height: auto !important;
      //overflow: visible;
    }
  }

  .MuiPaper-root {
    //width: fit-content !important;
    height: fit-content !important;
    break-inside: avoid;
    page-break-inside: avoid;
  }

  .MuiGrid-root {
    break-inside: avoid;
    page-break-inside: avoid;
  }

  .MuiTypography-root {
    font-size: 13px;
    margin-bottom: 0px;
  }

  div {
    //width: fit-content;
    height: fit-content !important;
    padding: 0px;
    margin: 0px;
    // display: relat !important;
    //position: relative !important;
    //overflow: visible !important;
    // margin-left: 0 !important;
  }

  b {
    display: inline !important;
  }

  // .MuiCardMedia-root {
  //   display: inline !important;
  //   visibility: visible !important;
  // }

  body,
  h1,
  h2,
  h3,
  ol,
  ul {
    display: block !important;
    position: relative !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
    margin-left: 0 !important;
    //
    //width: auto;
    //border: 0;
    //margin: 0 5%;
    //padding: 0;
    //float: none;
    //position: static;
  }
}
