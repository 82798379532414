@media all {
  .show-in-print {
    display: none;
  }
}

.form-print-subtitle {
  font-size: 12px !important;
  font-weight: 500;
  display: 'flex';
  color: #919191;
}

.form-print-subtitle-medium {
  font-size: 18px !important;
  font-weight: 600;
  display: 'flex';
  color: #919191;
}

.form-print-subtitle-big {
  font-size: 24px;
  font-weight: 'bold';
  text-align: center;
  color: #919191;
}

.form-print-title-small {
  font-size: 16px;
  font-weight: 'bold';
  padding: 10px;
}

.form-print-title-medium {
  font-size: 21px;
  padding: 10px;
}

.form-print-title {
  font-size: 27px;
  padding: 10px;
}

@media print {
  .page-break-before {
    margin-top: 1rem;
    //display: block;
    //background-color: red !important;
    //visibility: hidden;
    break-before: always !important;
    page-break-before: always !important;
  }

  .show-in-print {
    display: block;
  }

  .allow-print-break {
    break-inside: auto !important;
    page-break-inside: auto !important;
  }

  .avoid-print-break {
    break-inside: avoid !important;
    page-break-inside: avoid !important;
  }
}
